
/* eslint-disable */
import {
  defineComponent,
  ref,
  Sorting,
  Condition,
  PropType,
  onMounted,
  Operator
} from "@cloudfun/core";
import { VxeGridPropTypes, VxePulldownInstance } from "vxe-table";
import Grid, { GridOptions, TreeConfig } from "@/cloudfun/components/Grid.vue";
import VueTagsInput from "@sipec/vue3-tags-input";

export type SelectBoxOptions = {
  /** 資料主鍵 */
  rowId: string;
  /** 文字欄位 */
  textField: string;
  /** 值欄位 */
  valueField: string;
  /** 格式化文字 */
  formatText?: (row: any) => Promise<string>;
  /** 所有欄位 */
  columns: VxeGridPropTypes.Columns;
  /** 提示文字 */
  placeholder?: string;
  /** 展開方向 */
  placement?: "top" | "bottom";
  /** 是否複選 */
  multiselect?: boolean;
  /** 是否唯讀 */
  readonly?: boolean;
  /** 顯示搜尋框 */
  showSearch?: boolean;
  /** 顯示表頭 */
  showHeader?: boolean;
  /** 下拉寬度 */
  dropdownWidth?: number | string;
  /** 下拉高度 */
  dropdownHeight?: number | string;
  /** 當資料不存在時新增資料 */
  addIfNotExists?: boolean;
  /** 不能選擇的資料行 */
  disallowSelectedRowIds?: any[];
  /** 分頁設定 */
  pagerConfig?: VxeGridPropTypes.PagerConfig;
  /** 樹狀結構設定 */
  treeConfig?: TreeConfig;
  /** 可提供的承諾 */
  promises: {
    /** 以傳入值尋找資料 */
    find: (value: any) => Promise<any>;
    /** 查詢資料 */
    query: (params: {
      page: number;
      pageSize: number;
      keyword?: string;
      sortings?: Sorting[];
      condition?: Condition;
    }) => Promise<{ data: any[]; totalCount: number }>;
    /** 新增資料 */
    insert?: (row: any) => Promise<any>;
  };
  /** 是否轉移至最上層 */
  transfer?: boolean;
};

export default defineComponent({
  components: {
    Grid,
    VueTagsInput
  },
  props: {
    /** 綁定值 */
    modelValue: {
      type: [String, Number, Array] as PropType<
        string | number | string[] | number[] | undefined
      >
    },
    /** 資料主鍵 */
    rowId: { type: String, required: true },
    /** 文字欄位 */
    textField: { type: String, required: true },
    /** 值欄位 */
    valueField: { type: String, required: true },
    /** 格式化文字 */
    formatText: { type: Function as PropType<(rows: any) => Promise<string>> },
    /** 所有欄位 */
    columns: {
      type: Array as PropType<VxeGridPropTypes.Columns>,
      required: true
    },
    /** 提示文字 */
    placeholder: String,
    /** 展開方向 */
    placement: {
      type: String as PropType<"top" | "bottom">,
      default: "bottom"
    },
    /** 是否複選 */
    multiselect: { type: Boolean, default: false },
    /** 是否唯讀 */
    readonly: { type: Boolean, default: false },
    /** 顯示搜尋框 */
    showSearch: { type: Boolean, default: true },
    /** 顯示表頭 */
    showHeader: { type: Boolean, default: undefined },
    /** 下拉寬度 */
    dropdownWidth: { type: [Number, String] },
    /** 下拉高度 */
    dropdownHeight: { type: [Number, String], default: 282 },
    /** 當資料不存在時新增資料 */
    addIfNotExists: Boolean,
    /** 不能選擇的資料行 */
    disallowSelectedRowIds: { type: Array as PropType<any[]>, default: [] },
    /** 分頁設定 */
    pagerConfig: {
      type: Object as PropType<VxeGridPropTypes.PagerConfig>,
      default: {
        size: "mini",
        currentPage: 1,
        pageSize: 5,
        layouts: ["PrevPage", "Jump", "PageCount", "NextPage"],
        autoHidden: true,
        className: "border-b-2 rounded-b"
      }
    },
    /** 樹狀結構設定 */
    treeConfig: { type: Object as PropType<TreeConfig> },
    /** 可提供的承諾 */
    promises: {
      type: Object as PropType<{
        /** 以傳入值尋找資料 */
        find: (value: any) => Promise<any>;
        /** 查詢資料 */
        query: (params: {
          page: number;
          pageSize: number;
          keyword?: string;
          sortings?: Sorting[];
          condition?: Condition;
        }) => Promise<{ data: any[]; totalCount: number }>;
        /** 新增資料 */
        insert?: (row: any) => Promise<any>;
      }>,
      required: true
    },
    /** 是否轉移至最上層 */
    transfer: Boolean
  },
  setup(props) {
    const instance = ref({} as VxePulldownInstance);
    const selectedRows = ref<any[]>([]);
    const tagsInput = ref<any>({});
    const tag = ref("");
    const grid = ref<any>({});
    const text = ref<string>("");
    const value = ref<any | any[]>(props.modelValue);
    const keyword = ref<string>("");

    onMounted(async () => {
      if (props.modelValue) {
        const values = Array.isArray(props.modelValue)
          ? props.modelValue
          : [props.modelValue];
        for (const value of values) {
          await props.promises
            .find(value)
            .then(async row => {
              selectedRows.value.push({
                id: row[props.rowId],
                text: props.formatText
                  ? await props.formatText(row)
                  : row[props.textField],
                value: row[props.valueField]
              });
            })
            .catch(error =>
              console.log("SelectBox: Failure to find model value: ", error)
            );
        }
        value.value = selectedRows.value[0]?.value;
        if (!props.multiselect) {
          if (props.showSearch) keyword.value = selectedRows.value[0]?.text;
          else text.value = selectedRows.value[0]?.text;
        }
      }
    });

    const findChildRow = (rowId: any, children: any[]) => {
      for (const row of children) {
        if (row[props.rowId] === rowId) return row;
        if (props.treeConfig?.children && row[props.treeConfig.children]) {
          const child: any = findChildRow(
            rowId,
            row[props.treeConfig.children]
          );
          if (child) return child;
        }
      }
    };

    const findGridRow = (rowId: any) => {
      if (!grid.value.getData) return undefined;
      const data = grid.value.getData();
      return findChildRow(rowId, data);
    };

    const gridOptions: GridOptions = {
      rowId: props.rowId,
      size: "mini",
      height: "auto",
      autoResize: true,
      multiselect: props.multiselect,
      columns: props.columns,
      showHeader:
        props.showHeader !== undefined
          ? props.showHeader
          : props.columns.length > 1,
      pagerConfig: props.pagerConfig,
      treeConfig: props.treeConfig,
      stripe: props.treeConfig === undefined,
      promises: { query: props.promises.query },
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      checkboxConfig: {
        reserve: true,
        checkRowKeys: props.modelValue
          ? Array.isArray(props.modelValue)
            ? props.modelValue
            : typeof props.modelValue === "string"
            ? [props.modelValue]
            : [props.modelValue]
          : undefined,
        checkMethod: ({ row }) =>
          !props.readonly &&
          !props.disallowSelectedRowIds.some(id => id === row[props.rowId]),
        strict: props.readonly
      }
    };

    return {
      instance,
      selectedRows,
      tagsInput,
      tag,
      grid,
      gridOptions,
      findGridRow,
      text,
      value,
      keyword
    };
  },
  watch: {
    async modelValue(current, original) {
      if (current != this.value) {
        this.selectedRows = [];
        if (current) {
          const values = Array.isArray(current) ? current : [current];
          for (const value of values) {
            await this.promises
              .find(value)
              .then(async row => {
                if (this.grid.setCheckboxRow)
                  this.grid.setCheckboxRow(row, true);
                this.selectedRows.push({
                  id: row[this.rowId],
                  text: this.formatText
                    ? await this.formatText(row)
                    : row[this.textField],
                  value: row[this.valueField]
                });
              })
              .catch(error =>
                console.log("SelectBox: Failure to find model value: ", error)
              );
          }
        }
        if (this.multiselect) {
          this.gridOptions.checkboxConfig!.checkRowKeys = Array.isArray(current)
            ? current
            : [current];
          if (this.grid.reload) this.grid.reload();
        } else {
          this.value = this.selectedRows[0]?.value;
          if (this.showSearch) this.keyword = this.selectedRows[0]?.text;
          else this.text = this.selectedRows[0]?.text;
        }
      }
    }
  },
  methods: {
    computeValue() {
      if (this.multiselect)
        return this.selectedRows.map(e =>
          e.row ? e.row[this.valueField] : e.value
        );
      else {
        if (this.showSearch) {
          this.keyword = this.selectedRows[0]?.text;
          if (this.grid.refresh && this.keyword !== this.grid.keyword) {
            this.grid.keyword = this.keyword;
            this.grid.refresh();
          }
        } else this.text = this.selectedRows[0]?.text;
      }
      return this.selectedRows[0]?.value;
    },
    async onFocus() {
      await this.instance.showPanel();
      this.computeValue();
    },
    async onHidePanel() {
      if (!this.multiselect && this.showSearch && this.grid.keyword) {
        const data = this.grid.getData();
        if (data.length === 1) {
          const row = data[0];
          this.selectedRows = [
            {
              id: row[this.rowId],
              text: this.formatText
                ? await this.formatText(row)
                : row[this.textField],
              value: row[this.valueField],
              row: row
            }
          ];
          this.value = this.computeValue();
          this.$emit("update:modelValue", this.value);
          this.$emit("change", this.value);
        }
        if (!this.selectedRows.length) this.keyword = "";
      }
    },
    onKeywordChange() {
      if (!this.multiselect && this.showSearch) {
        this.grid.keyword = this.keyword;
        if (this.selectedRows.length) {
          this.selectedRows = [];
          this.value = undefined;
          this.$emit("update:modelValue", this.value);
          this.$emit("change", this.value);
        }
      }
      if (this.grid.refresh) this.grid.refresh();
    },
    async onSelectedRowChanged(gridRow: any) {
      if (this.readonly || this.multiselect) return;
      if (this.selectedRows.some(e => e.id === gridRow[this.rowId])) return;
      if (this.disallowSelectedRowIds?.includes(gridRow[this.rowId]))
        this.$emit("disallowSelect", gridRow);
      const row = {
        id: gridRow[this.rowId],
        text: this.formatText
          ? await this.formatText(gridRow)
          : gridRow[this.textField],
        value: gridRow[this.valueField],
        row: gridRow
      };
      this.selectedRows = [row];
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    onClear() {
      this.selectedRows = [];
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onCheckboxChange({ checked, records, reserves, row }: any) {
      if (this.readonly) return;
      if (checked) {
        if (this.disallowSelectedRowIds?.includes(row[this.rowId]))
          this.$emit("disallowSelect", row);
        let checkingRows = [...records];
        if (this.treeConfig?.children)
          checkingRows = checkingRows.filter(
            (e: any) => !e[this.treeConfig!.children!]?.length
          ); // eslint-disable-line
        for (const record of checkingRows) {
          const selectedRow = this.selectedRows.find(
            e => e.id === record[this.rowId]
          );
          if (selectedRow) {
            selectedRow.row = record;
            continue;
          }
          this.selectedRows.push({
            id: record[this.rowId],
            text: this.formatText
              ? await this.formatText(record)
              : record[this.textField],
            value: record[this.valueField],
            row: record
          });
        }
      } else {
        const selectedRows: any[] = [];
        for (const record of [...records, ...reserves]) {
          const selectedRow = this.selectedRows.find(
            e => e.id === record[this.rowId]
          );
          if (selectedRow) {
            selectedRow.row = record;
            selectedRows.push(selectedRow);
          }
        }
        this.selectedRows = selectedRows;
      }
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onCheckboxAll({ checked, records, reserves }: any) {
      if (this.readonly) return;
      if (checked) {
        for (const record of records) {
          const selectedRow = this.selectedRows.find(
            e => e.id === record[this.rowId]
          );
          if (selectedRow) {
            selectedRow.row = record;
            continue;
          }
          this.selectedRows.push({
            id: record[this.rowId],
            text: this.formatText
              ? await this.formatText(record)
              : record[this.textField],
            value: record[this.valueField],
            row: record
          });
        }
      } else {
        const selectedRows: any[] = [];
        for (const record of reserves) {
          const selectedRow = this.selectedRows.find(
            e => e.id === record[this.rowId]
          );
          if (selectedRow) {
            selectedRow.row = record;
            selectedRows.push(selectedRow);
          }
        }
        this.selectedRows = selectedRows;
      }
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onBeforeDeletingTag({ tag }: any) {
      if (this.readonly) return;
      if (!this.grid.getData) {
        this.instance.showPanel();
        return;
      }
      const unselectedRow = this.selectedRows.find((e: any) => e.id === tag.id);
      if (!unselectedRow.row)
        unselectedRow.row = this.findGridRow(unselectedRow.id);
      await this.grid.setCheckboxRow(
        unselectedRow.row || { [this.rowId]: tag.id },
        false
      );
      this.selectedRows = this.selectedRows.filter(e => e.id !== tag.id);
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async onBeforeAddingTag() {
      if (
        this.selectedRows.some(e => e.row && e.row[this.textField] === this.tag)
      )
        return;
      const pageinate = await this.promises.query({
        page: 1,
        pageSize: 1,
        condition: new Condition(this.textField, Operator.Equal, this.tag)
      });
      let row: any = undefined;
      if (pageinate?.data?.length) {
        row = pageinate.data[0];
      } else if (this.addIfNotExists && this.promises.insert) {
        row = await this.promises.insert({ [this.textField]: this.tag });
      }

      await this.grid.refresh();
      const gridRow = this.findGridRow(row[this.rowId]);
      if (gridRow) row = gridRow;
      await this.grid.setCheckboxRow(row, true);
      this.selectedRows.push({
        id: row[this.rowId],
        text: this.formatText
          ? await this.formatText(row)
          : row[this.textField],
        value: row[this.valueField],
        row: gridRow || undefined
      });

      this.tag = "";
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    async togglePanel() {
      if (this.instance.isPanelVisible()) await this.instance.hidePanel();
      else await this.onFocus();
    },
    async onPageChanged() {
      for (const row of this.selectedRows) {
        const gridRow = this.findGridRow(row.value);
        if (gridRow) await this.grid.setCheckboxRow(gridRow, true);
      }
    }
  }
});
